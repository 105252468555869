import {Alert, Box, Button, Snackbar} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import {Link, useParams} from "react-router-dom";
import {useVendingMachine} from "../../api/overpass/vendingmachine";
import {VendingMachine} from "./VendingMachine";
import {LoadingVendingMachine} from "./LoadingVendingMachine";

export function Details() {
    const { id } = useParams();

    const [loading, vendingMachine, err] = useVendingMachine(id);

    return (
        <Box sx={{padding: {xs: "16px", lg: "16px 32px"}}}>
            <Snackbar open={err !== undefined} autoHideDuration={6000}>
                <Alert severity="error">{err?.name}: {err?.message}</Alert>
            </Snackbar>
            <div style={{marginBottom: "16px"}}>
                <Button component={Link} to="/">
                    <ArrowBack/> Back to map
                </Button>
            </div>
            {
                loading && <LoadingVendingMachine />
            }
            {
                !!vendingMachine && !loading && <VendingMachine data={vendingMachine} />
            }
        </Box>
    )
}
