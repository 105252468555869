import {overpassInterpreterUrl, OverpassNode} from "./overpass";
import {useEffect, useState} from "react";

export interface VendingMachineData {
    name: string;
    offeredProducts: string[];
    openingHours?: string;
    operator?: string;
    paymentOptions?: string[];
    website?: string;
    osmData: OverpassNode;
}

function mapOverpassToMachine(overpass: OverpassNode): VendingMachineData {
    return {
        name: overpassName(overpass),
        osmData: overpass,
        offeredProducts: overpassOfferedProducts(overpass),
        openingHours: overpass.tags.opening_hours,
        operator: overpass.tags.operator,
        website: overpass.tags.website,
        paymentOptions: overpassPaymentOptions(overpass),
    }
}

function overpassName(overpass: OverpassNode): string {
    if ("name" in overpass.tags) {
        return capitalize(overpass.tags.name);
    }

    if ("vending" in overpass.tags) {
        return capitalize(overpass.tags.vending.replaceAll(';', ', ').replaceAll('_', ' '));
    }

    return "Unknown vending machine";
}

function overpassOfferedProducts(overpass: OverpassNode): string[] {
    if (!("vending" in overpass.tags)) {
        return [];
    }

    return overpass.tags.vending.split(";");
}

function overpassPaymentOptions(overpass: OverpassNode): string[] {
    const paymentOptions: string[] = [];

    for (let [tag, value] of Object.entries(overpass.tags)) {
        if (!tag.startsWith('payment:') || value !== 'yes') {
            continue;
        }

        const method = tag.substring('payment:'.length);
        paymentOptions.push(method);
    }

    return paymentOptions;
}

function capitalize(input: string): string {
    if (input.length === 0) {
        return "";
    }

    return input[0].toUpperCase() + input.slice(1);
}

export function useVendingMachine(id?: string): [boolean, VendingMachineData?, Error?] {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<VendingMachineData>()
    const [err, setErr] = useState<Error>();

    useEffect(() => {
        if (!id) {
            return;
        }

        const abortController = new AbortController();
        const {signal} = abortController;

        setLoading(true);

        const url = overpassInterpreterUrl + '?data=' + encodeURIComponent(`[out:json][timeout:2];node(${id});out body;`)

        fetch(url, {signal}).then(response => response.json()).then(response => {
            setLoading(false);

            if (response.elements && response.elements.length && response.elements.length === 1) {
                setData(mapOverpassToMachine(response.elements[0]));
                setErr(undefined)
            } else {
                setErr(new Error("node not found"));
            }
        }).catch(responseErr => {
            if (responseErr.name === "AbortError") {
                return;
            }

            setLoading(false);
            setData(undefined);
            setErr(responseErr);
        });

        return () => {
            abortController.abort();
        }
    }, [id])

    return [loading, data, err];
}
