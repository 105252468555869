import {
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography
} from "@mui/material";
import {Business, Category, Schedule} from "@mui/icons-material";

import {VendingMachineData} from "../../api/overpass/vendingmachine";
import {PaymentOptions} from "./PaymentOptions";
import {OfferedProducts} from "./OfferedProducts";

export function VendingMachine({data}: { data: VendingMachineData }) {
    return (
        <>
            <Typography gutterBottom variant="h5" component="h5">
                {data.name}
            </Typography>
            <List disablePadding>
                <ListSubheader disableGutters>General</ListSubheader>
                {
                    data.offeredProducts ? <OfferedProducts products={data.offeredProducts}/> :
                        <ListItem disableGutters>
                            <ListItemIcon><Category color="primary"/></ListItemIcon>
                            <ListItemText><Typography fontStyle="italic">No products listed</Typography></ListItemText>
                        </ListItem>
                }
                <ListItem disableGutters>
                    <ListItemIcon>
                        <Schedule color="primary"/>
                    </ListItemIcon>
                    {
                        data.openingHours ? <ListItemText primary={data.openingHours}/> :
                            <ListItemText><Typography fontStyle="italic">No opening hours
                                listed</Typography></ListItemText>
                    }
                </ListItem>
                <ListItem disableGutters>
                    <ListItemIcon>
                        <Business color="primary"/>
                    </ListItemIcon>
                    {
                        data.operator ? <ListItemText primary={data.operator}/> : (<ListItemText>
                            <Typography fontStyle="italic">No operator listed</Typography>
                        </ListItemText>)
                    }
                </ListItem>
                {
                    data.website ? <ListItem>
                        <ListItemButton component="a" href={data.website} target="_blank"
                                        rel="noopener nofollow noreferrer">
                            <ListItemIcon><Link color="primary"/></ListItemIcon>
                            <ListItemText primary={data.website}></ListItemText>
                        </ListItemButton>
                    </ListItem> : <></>
                }
                <ListSubheader disableGutters>
                    Accepted payment options
                </ListSubheader>
                {
                    data.paymentOptions && data.paymentOptions.length !== 0 ?
                        <PaymentOptions options={data.paymentOptions}/> : (
                            <ListItem disableGutters>
                                <ListItemText>
                                    <Typography fontStyle="italic">No payment options listed</Typography>
                                </ListItemText>
                            </ListItem>
                        )
                }
            </List>

        </>
    )
}

// <pre>{JSON.stringify(data, undefined, 2)}</pre>
