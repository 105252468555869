import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {LatLngBounds, Map as LeafletMap} from "leaflet";
import {useCallback, useEffect, useRef, useState} from "react";
import {Alert, Fab, LinearProgress, Snackbar} from "@mui/material";
import {LocationSearching} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

import {useVendingMachines} from "../../api/overpass/overpass";
import {MarkerIcon} from "./markerIcon";

import "leaflet/dist/leaflet.css";

const defaultBounds: LatLngBounds = new LatLngBounds([49.1217,9.1430], [49.1620,9.2701]);
const defaultZoom = 13;

export function Map() {
    const mapRef = useRef<LeafletMap>(null);
    const navigate = useNavigate();

    const [currentBounds, setCurrentBounds] = useState<LatLngBounds>(defaultBounds);
    const [currentZoom, setCurrentZoom] = useState<number>(defaultZoom);

    const moveToCurrent = useCallback(() => {
        navigator.geolocation.getCurrentPosition(position => {
            if (!mapRef.current || position.coords.latitude == null || position.coords.longitude == null) {
                return;
            }

            let zoom = mapRef.current.getZoom();

            if (zoom < 13) {
                zoom = 13;
            }

            mapRef.current.setView([position.coords.latitude, position.coords.longitude], zoom);
            setCurrentBounds(mapRef.current.getBounds());
        })
    }, [])

    const onMove = useCallback(() => {
        console.log('move');
        if (mapRef.current) {
            setCurrentBounds(mapRef.current.getBounds());
        }
    }, [mapRef]);

    const onZoom = useCallback(() => {
        console.log('zoom');
        if (mapRef.current) {
            setCurrentZoom(mapRef.current.getZoom());
        }
    }, [mapRef]);

    useEffect(() => {
        const currentMapRef = mapRef.current;
        currentMapRef?.on('move', onMove);
        currentMapRef?.on('zoom', onZoom);

        return () => {
            currentMapRef?.off('move', onMove);
            currentMapRef?.off('zoom', onZoom);
        }
    }, [mapRef, mapRef.current, onMove, onZoom]);

    const [loading, vendingMachines, err] = useVendingMachines(currentBounds, currentZoom);

    return (
        <div style={{width: "100%", height: "100%"}}>
            {
                loading && <LinearProgress />
            }
            <Snackbar open={err !== undefined} autoHideDuration={6000}>
                <Alert severity="error">{err?.name}: {err?.message}</Alert>
            </Snackbar>
            <MapContainer bounds={currentBounds}
                          scrollWheelZoom={true}
                          style={{height: "100%"}}
                          ref={mapRef}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    vendingMachines.map(v => (
                        <Marker position={[v.lat, v.lon]} icon={MarkerIcon} key={v.id} eventHandlers={{
                            click: () => {
                                navigate(`/details/${v.id}`);
                            }
                        }} />
                    ))
                }
                <Fab onClick={moveToCurrent} color="default" style={{position: "absolute", left: 20, bottom: 20}}
                     title="Go to current location">
                    <LocationSearching/>
                </Fab>
            </MapContainer>
        </div>

    )
}