import {List, ListItem, ListItemIcon, ListItemText, ListSubheader, Skeleton, Typography} from "@mui/material";

export function LoadingVendingMachine() {
    return (
        <>
            <Typography gutterBottom variant="h5" component="div">
                <Skeleton variant="text" height={32} animation="wave"/>
            </Typography>
            <List disablePadding>
                <ListSubheader disableGutters><Skeleton variant="text" animation="wave"/></ListSubheader>
                {
                    ['offeredProducts', 'openingHours', 'operator', 'website'].map((key) => (<ListItem key={key} disableGutters>
                        <ListItemIcon><Skeleton variant="circular" animation="wave" width={24} height={24}/></ListItemIcon>
                        <ListItemText><Skeleton variant="text" animation="wave"/></ListItemText>
                    </ListItem>))
                }
                <ListSubheader disableGutters><Skeleton variant="text" animation="wave"/></ListSubheader>
                <ListItem disableGutters>
                    <ListItemIcon><Skeleton variant="circular" animation="wave" width={24} height={24}/></ListItemIcon>
                    <ListItemText><Skeleton variant="text" animation="wave"/></ListItemText>
                </ListItem>
                <ListItem disableGutters>
                    <ListItemIcon><Skeleton variant="circular" animation="wave" width={24} height={24}/></ListItemIcon>
                    <ListItemText><Skeleton variant="text" animation="wave"/></ListItemText>
                </ListItem>
            </List>
        </>
    )
}