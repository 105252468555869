import React from "react";
import {AppBar, Drawer, Toolbar, Typography} from "@mui/material";

export function Layout({main, side}: { main: JSX.Element, side?: JSX.Element }) {
    const hasSideContent = side !== undefined;

    return (
        <>
            <AppBar position="static" color="transparent">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        Vending Machines
                    </Typography>
                </Toolbar>
            </AppBar>

            <div style={{height: "100%"}}>
                {main}
            </div>

            <Drawer variant="persistent" anchor="right" open={hasSideContent} hideBackdrop={true} PaperProps={{sx: {width: {xs: "100%", lg: "500px"}}}}>
                {side}
            </Drawer>
        </>
    )
}
