import {EffectCallback, useEffect, useState} from "react";
import {LatLngBounds} from "leaflet";

export const overpassInterpreterUrl = 'https://overpass-api.de/api/interpreter';

interface OverpassElement {
    type: string;
    tags: OverpassTag[];
    boundingBox: LatLngBounds;
}

interface OverpassTag {
    name: string;
    value: string;
    operator: string;
}

export interface OverpassNode {
    type: 'node';
    id: number;
    lat: number;
    lon: number;
    tags: { [tag: string]: string };
}

class OverpassQuery {
    constructor(
        private elements: OverpassElement[],
    ) {
    }

    toOverpassQL(): string {
        const elements = this.elements.map(
            elem => `${elem.type}${elem.tags.map(
                tag => `[${tag.name}${tag.operator}${tag.value}]`,
            ).join('')}(${elem.boundingBox.getSouth()},${elem.boundingBox.getWest()},${elem.boundingBox.getNorth()},${elem.boundingBox.getEast()})`,
        );

        const query = `[out:json][timeout:5];${elements.join(';')};out body;`;

        console.log(query);

        return query;
    }

    toQueryUrl(): string {
        const query = this.toOverpassQL();

        const params = new URLSearchParams();
        params.append('data', query);

        return overpassInterpreterUrl + '?' + params.toString();
    }
}

export function useVendingMachines(boundingBox: LatLngBounds, zoom: number): [boolean, OverpassNode[], Error?] {
    console.log('useVendingMachines called', boundingBox, zoom);
    const [loading, setLoading] = useState<boolean>(false);
    const [err, setErr] = useState<Error>();
    const [data, setData] = useState<OverpassNode[]>([]);

    useDebouncedEffect(() => {
        if (zoom < 13) {
            console.log('not loading because zoom below 13');
            setLoading(false);
            setErr(undefined);
            setData([]);

            return;
        }

        setLoading(true);
        console.log('start loading');
        const query = new OverpassQuery([{
            boundingBox: boundingBox,
            tags: [{
                name: 'amenity',
                operator: '=',
                value: 'vending_machine',
            }, {
                name: 'vending',
                operator: '!=',
                value: 'parking_tickets',
            }, {
                name: 'vending',
                operator: '!=',
                value: 'cigarettes',
            }],
            type: 'node',
        }]);

        fetch(query.toQueryUrl())
            .then(response => response.json())
            .then(response => {
                console.log('end loading', response);
                setData(response.elements)
                setLoading(false);
                setErr(undefined);
            })
            .catch(responseErr => {
                console.log('vending machines error', responseErr);
                setErr(responseErr);
                setLoading(false);
            });
    }, [boundingBox, zoom], 1000);

    return [loading, data, err];
}

export const useDebouncedEffect = (effect: EffectCallback, deps: any[], delay: number) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);

        return () => clearTimeout(handler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay]);
}
