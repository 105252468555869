import {SvgIcon} from "@mui/material";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";

export function CoinsIcon(props: SvgIconProps) {
    return <SvgIcon {...props} version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

        <g>
            <path
                d="m480.17 290.75c0 105.97-85.906 191.88-191.88 191.88-26.493 0-51.731-5.3691-74.687-15.079s-31.86-23.076-31.86-23.076c283.33 38.076 271.91-208.69 261.49-263.9 0 0 12.147 12.532 21.857 35.487 9.7095 22.956 15.079 48.194 15.079 74.687z"
                strokeOpacity="0"/>
        </g>
        <g>
            <circle cx="228.5" cy="231.42" r="191.88" />
            <text x="150.95071" y="311.6748" fill="#000000" fontFamily="sans-serif"
                  fontSize="256px" letterSpacing="0px" wordSpacing="0px"
                  style={{lineHeight:1.25}} xmlSpace="preserve"><tspan x="150.95071" y="311.6748" fill="#ffffff" fontFamily="sans-serif" fontSize="256px">$</tspan></text>
        </g>

    </SvgIcon>
}