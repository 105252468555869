import {capitalize, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Category} from "@mui/icons-material";

function productToText(product: string): string {
    return capitalize(product.replaceAll('_', ' '));
}

export function OfferedProducts({products}: { products: string[] }) {
    return <>
        {
            products.map(product => (
                <ListItem key={product} disableGutters>
                    <ListItemIcon><Category color="primary"/></ListItemIcon>
                    <ListItemText primary={productToText(product)}/>
                </ListItem>
            ))
        }
    </>
}