import {capitalize, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Badge, AttachMoney, Contactless, CreditCard, PointOfSale} from "@mui/icons-material";

import {CoinsIcon} from './CoinsIcon';

function optionToText(option: string): string {
    return capitalize(option.replaceAll('_', ' '));
}

function optionToIcon(option: string): JSX.Element {
    const optionIconMap: { [option: string]: JSX.Element } = {
        'account_cards': <Badge color="primary"/>,
        'coins': <CoinsIcon color="primary"/>,
        'cards': <CreditCard color="primary"/>,
        'credit_cards': <CreditCard color="primary"/>,
        'debit_cards': <CreditCard color="primary"/>,
        'contactless': <Contactless color="primary"/>,
        'notes': <AttachMoney color="primary"/>,
    };

    return optionIconMap[option] ?? <PointOfSale color="primary"/>;
}

export function PaymentOptions({options}: { options: string[] }) {
    return <>
        {options.map((option: string) => (
            <ListItem key={option} disableGutters>
                <ListItemIcon>
                    {optionToIcon(option)}
                </ListItemIcon>
                <ListItemText primary={optionToText(option)}/>
            </ListItem>
        ))}
    </>
}