import React from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import {Route, Routes} from "react-router-dom";
import {Layout} from "./components/layout/Layout";
import {Map} from "./components/map/Map";
import {Details} from "./components/details/Details";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';

export function App() {
    return (
        <>
            <CssBaseline/>
            <Routes>
                <Route path="/" element={<Layout main={<Map/>}/>}/>
                <Route path="/details/:id" element={<Layout main={<Map/>} side={<Details/>}/>}/>
            </Routes>
        </>
    );
}
